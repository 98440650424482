import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../styles/style.css"

const EmpleoForm = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(
        relativeDirectory: { eq: "empleo" }
        name: { eq: "cabecera-empleo" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Perfil Administración de sistemas Línux y BigData | Qualoom</title>
        <meta name="title" content="Perfil Administración de sistemas Línux y BigData | Qualoom"/>
        <meta name="description" content="Qualoom busca perfiles con experiencia técnica en ecosistemas Linux y Big Data, tecnologías Spark, Hadoop (Cloudera) con ganas de seguir aprendiendo."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/perfil-administracion-de-sistemas-linux-y-bigdata/"/>
        <link rel="canonical" href="https://www.qualoom.es/perfil-administracion-de-sistemas-linux-y-bigdata/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
       <meta property="og:url" content="https://www.qualoom.es/perfil-administracion-de-sistemas-linux-y-bigdata/"/>
        <meta property="og:title" content="Perfil Administración de sistemas Línux y BigData | Qualoom"/>
        <meta property="og:description" content="Qualoom busca perfiles con experiencia técnica en ecosistemas Linux y Big Data, tecnologías Spark, Hadoop (Cloudera) con ganas de seguir aprendiendo."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/2RlgyCrAEUpdKRxj8UWjUG/f51308c39df9bfd9f8aeabe572a9cd62/tw_cards_empleo.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/perfil-administracion-de-sistemas-linux-y-bigdata/"/>
        <meta property="twitter:title" content="Perfil Administración de sistemas Línux y BigData | Qualoom"/>
        <meta property="twitter:description" content="Qualoom busca perfiles con experiencia técnica en ecosistemas Linux y Big Data, tecnologías Spark, Hadoop (Cloudera) con ganas de seguir aprendiendo."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/2RlgyCrAEUpdKRxj8UWjUG/f51308c39df9bfd9f8aeabe572a9cd62/tw_cards_empleo.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

      </Helmet>{" "}
      <BackgroundImage fluid={data.image1.childImageSharp.fluid}>
        <div className="background-empleo">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Perfil Administración de sistemas Línux y BigData</h1>
              <p>Qualoom busca incorporar a su equipo perfiles con experiencia técnica en ecosistemas Linux y Big Data, tecnologías Spark, Hadoop (Cloudera) con ganas de seguir aprendiendo y con capacidad de desarrollar su carrera en una empresa estable ypuntura en nuevas tecnologías para importante cliente.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="job">
        <h3>Descripción del puesto</h3>
        <ol>
          <li>Ofrecemos la oportunidad de crecer profesionalmente en proyectos innovadores y tecnológicamente complejos</li>
          <li>El candidato formará parte del equipo que administra y opera los sistemas de información de un importante cliente a nivel nacional.</li>
          <li>Desarrollará las tareas de administración de plataformas Linux, Windows y sistemas de Bigdata Hadoop y Spark, aplicando la mejor estrategia de gestión de sistemas y tratamiento de entornos de grandes volúmenes de datos.</li>
          <li>Además tendrá la oportunida de aprender y abordar infinidad de restos tecnológicos a nivel formativo y profesional dado que al ofrecer este servicio de operación se trabaja mano a mano con multitud de áreas del cliente (ingenieros de datos, equipos de automatización, capa de negocio)</li>
          <li>Se realizará un seguimiento personalizado de su evolución, atendiendo a nuevas necesidades tecnológicas del mercado y a capacidades propias para adquirir y reforzar su conocimiento permitiendo su crecimiento profesional y personal.</li>
        </ol>
        <h3>Requisitos del perfil</h3>
        <ol>
          <li>Se requiere experiencia en administración de sistemas Linux (SysAdmin).</li>
          <li>Se requiere experiencia o conocimientos en plataformas BigData (Hadoop y Spark) y herramientas como Airflow o/y Nifi.</li>
          <li>Capacidad de análisis y buena comunicación.</li>
          <li>Ser el catalizador/a del cambio y mejora continua.</li>
          <li>Fomentar el trabajo auto gestionado ayudando en la toma de decisiones y facilitando la consecución de los objetivos establecidos.</li>
          <li>Velar porque los integrantes del equipo asimilen la nueva metodología y su implantación sea la óptima.</li>
          <li>Aportar mejoras continuas adaptándose a la cultura y el background de cada uno de los equipos.</li>
        </ol>
        <h3>Te ofrecemos</h3>
        <ol>
          <li>El reto de participar en proyectos innovadores de entornos de sistemas y Bigdata para importante cliente</li>
          <li>Colaborar con equipos multidisciplinares obteniendo una visión 360º de la compañía y clientes</li>
          <li>Certificaciones y formación</li>
          <li>Trabajo con grandes profesionales y en equipos multidisciplinares.</li>
          <li>Plan de carrera adecuado a tus necesidades.</li>
          <li>Contrato por obra y servicio hasta el 01/01/2021 con alta probabilidad de renovación tras 5 años con este cliente</li>
          <li>Algo que seguro no podrás rechazar. Trabajar en la zona sur de Madrid (Móstoles), con las ventajas que esto conlleva, posibilidad de ir a comer casa, tiempo de transporte al trabajo apenas existente.</li>
          <li>Remuneración: Según experiencia y conocimietos aportados</li>
        </ol>
        <a href="mailto:empleo@qualoom.es ?subject=Candidatura Perfil Administración de sistemas Línux y BigData &body=Hola, %0A%0A Estoy interesado en formar parte del equipo de Qualoom. %0A%0A Les remito los siguientes datos y adjunto mi CV para mi candidatura: %0A%0A%09 Nombre: %0A%0A%09 Apellidos: %0A%0A%09 Contacto: %0A%0A%0A Saludos." className="button-job">Envíanos tu CV para participar en esta oportunidad</a>
      </div>
      <Footer />
    </div>
  )
}

export default EmpleoForm
